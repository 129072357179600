import React from "react";
import {
  Recipe,
  RecipeIngredientGroup,
  RecipeStepSection,
} from "@timandgareth/domain";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

function SimpleStringList({ strings }: { strings: string[] }) {
  return (
    <ul>
      {strings.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
}

function Ingredients({
  ingredients,
}: {
  ingredients: (RecipeIngredientGroup | string)[];
}) {
  if (ingredients.length === 0) {
    return null;
  }
  if (typeof ingredients[0] === "string") {
    return <SimpleStringList strings={ingredients as string[]} />;
  } else {
    return (
      <>
        {(ingredients as RecipeIngredientGroup[]).map(
          ({ name, items }, sectionIndex) => (
            <div key={sectionIndex}>
              <Typography variant="h4">{name}</Typography>
              <SimpleStringList strings={items} />
            </div>
          )
        )}
      </>
    );
  }
}

function Steps({ steps }: { steps: (RecipeStepSection | string)[] }) {
  if (steps.length === 0) {
    return null;
  }
  if (typeof steps[0] === "string") {
    return <SimpleStringList strings={steps as string[]} />;
  } else {
    return (
      <>
        {(steps as RecipeStepSection[]).map(
          ({ title, steps }, sectionIndex) => (
            <div key={sectionIndex}>
              <Typography variant="h4">{title}</Typography>
              <SimpleStringList strings={steps} />
            </div>
          )
        )}
      </>
    );
  }
}

export default function RecipeDetail() {
  const recipe = useLoaderData() as Recipe;
  const navigate = useNavigate();
  return (
    <>
      <Grid container>
        {!recipe && (
          <Grid item md={12}>
            <Box>
              <Typography sx={{ marginBottom: 2 }}>
                Uh oh... this recipe hasn't been written yet.
              </Typography>
            </Box>
          </Grid>
        )}
        {recipe && (
          <>
            <Grid item sm={12}>
              <Box>
                <Typography variant="h2">{recipe.title}</Typography>
                {recipe.quantity && (
                  <Typography sx={{ fontStyle: "italic" }}>
                    {recipe.quantity}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <Box>
                <Typography variant="h3">Ingredients</Typography>
                <Ingredients ingredients={recipe.ingredients} />
              </Box>
            </Grid>
            <Grid item md={6} sm={12}>
              <Box>
                <Typography variant="h3">Steps</Typography>
                <Steps steps={recipe.steps} />
              </Box>
            </Grid>
          </>
        )}
        <Grid item sm={12} sx={{ margin: 2 }}>
          <Box>
            <Button
              onClick={() => navigate("/recipes")}
              startIcon={<KeyboardArrowLeftIcon />}
            >
              Back to recipe list
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
