import React from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { chunk } from "lodash";

interface MenuItemProps {
  url: string;
  title: string;
  icon: React.ReactNode;
}

function MenuItem({ url, title, icon }: MenuItemProps) {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();
  const active =
    url !== "/" ? location.pathname.startsWith(url) : location.pathname === url;
  const sx = {
    paddingLeft: isMobileView ? 1 : 3,
    paddingRight: isMobileView ? 1 : 3,
  };
  return (
    <Button
      variant={active ? "contained" : "text"}
      onClick={() => navigate(url)}
      startIcon={icon}
      sx={sx}
    >
      {title}
    </Button>
  );
}

interface MenuItem {
  url: string;
  title: string;
  icon: JSX.Element;
}

interface MenuProps {
  menuItems: MenuItem[];
}

export default function Menu({ menuItems }: MenuProps) {
  const theme = useTheme();
  const isXsView = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmView = useMediaQuery(theme.breakpoints.down("md"));
  const maxItemsPerRow = isXsView ? 3 : isSmView ? 5 : 10;
  let rowCount = 0;
  let itemsPerRow = menuItems.length;
  do {
    rowCount++;
    itemsPerRow = Math.ceil(menuItems.length / rowCount);
  } while (itemsPerRow > maxItemsPerRow);
  const menuItemGroups = chunk(menuItems, itemsPerRow);
  return (
    <Grid
      container
      sx={{
        marginTop: {
          xs: 2,
        },
        textAlign: "center",
      }}
    >
      {menuItemGroups.map((menuItemGroup, groupIndex) => (
        <Grid item xs={12} key={groupIndex}>
          <ButtonGroup variant="text" size={isXsView ? "small" : "medium"}>
            {menuItemGroup.map((menuItemProps, itemIndex) => (
              <MenuItem key={itemIndex} {...menuItemProps} />
            ))}
          </ButtonGroup>
        </Grid>
      ))}
    </Grid>
  );
}
