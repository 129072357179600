import React from "react";
import { Recipe } from "@timandgareth/domain";
import { Box, Grid, Typography } from "@mui/material";
import { Link, useLoaderData } from "react-router-dom";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import { GermanTranslationDisclaimer } from "../components";

export default function RecipeList() {
  const { t } = useTranslation();
  const recipes = useLoaderData() as Recipe[];
  const sortedRecipes = orderBy(recipes, "title");
  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Box>
            <Typography variant="h2">{t("menu.recipes")}</Typography>
            <GermanTranslationDisclaimer />
            <ul>
              {sortedRecipes.map(({ title, isPrivate, key }) => (
                <li key={key} style={{ marginTop: 4 }}>
                  <Link to={`/recipes/${key}`}>{title}</Link>
                  {isPrivate && <span style={{ marginLeft: 2 }}>🔒</span>}
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
