import { Feature } from "@timandgareth/domain";
import { createContext } from "react";

export interface AuthorizationInfo {
  features: Feature[];
}

export const AuthorizationContext = createContext<AuthorizationInfo>({
  features: [],
});
