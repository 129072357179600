import React, { useContext } from "react";
import {
  CircularProgress,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CameraIcon from "@mui/icons-material/Camera";
import TheatersIcon from "@mui/icons-material/Theaters";
import HomeIcon from "@mui/icons-material/Home";
import PublicIcon from "@mui/icons-material/Public";
import InventoryIcon from "@mui/icons-material/Inventory";
import {
  FloatingMenu,
  LanguagePicker,
  LogoutButton,
  Menu,
  ScrollToTop,
} from "../components";
import { AuthorizationContext } from "../AuthorizationContext";
import { Feature } from "@timandgareth/domain";
import Header from "../Header";
import { useTranslation } from "react-i18next";
import ConstructionIcon from "@mui/icons-material/Construction";
import { useNavigate } from "react-router-dom";

interface GeneralLayoutProps {
  children: JSX.Element;
}

function MainMenu() {
  const { t } = useTranslation();
  const { features } = useContext(AuthorizationContext);

  if (features?.length === 0) {
    return <CircularProgress />;
  }

  const menuItems = [];
  if (features.includes(Feature.HOME)) {
    menuItems.push({ url: "/", title: t("menu.home"), icon: <HomeIcon /> });
  }
  if (features.includes(Feature.WEDDING)) {
    menuItems.push({
      url: "/wedding",
      title: t("menu.wedding"),
      icon: <FavoriteIcon />,
    });
  }
  if (features.includes(Feature.PHOTOS)) {
    menuItems.push({
      url: "/photos",
      title: t("menu.photos"),
      icon: <CameraIcon />,
    });
  }
  if (features.includes(Feature.TIM_PHOTO_ARCHIVE)) {
    menuItems.push({
      url: "/tims-photo-archive",
      title: t("menu.tims-photo-archive"),
      icon: <InventoryIcon />,
    });
  }
  if (features.includes(Feature.VIDEOS)) {
    menuItems.push({
      url: "/videos",
      title: t("menu.videos"),
      icon: <TheatersIcon />,
    });
  }
  if (features.includes(Feature.RECIPES)) {
    menuItems.push({
      url: "/recipes",
      title: t("menu.recipes"),
      icon: <RestaurantMenuIcon />,
    });
  }
  if (features.includes(Feature.PLACES)) {
    menuItems.push({
      url: "/places",
      title: t("menu.places"),
      icon: <PublicIcon />,
    });
  }
  return <Menu menuItems={menuItems} />;
}

function FloatingMenuItems() {
  const theme = useTheme();
  const { features } = useContext(AuthorizationContext);
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <LanguagePicker size={isMobileView ? "small" : "large"} />
      {features.includes(Feature.ADMIN) && (
        <IconButton
          onClick={() => navigate("/admin/users")}
          size={isMobileView ? "small" : "large"}
        >
          <ConstructionIcon />
        </IconButton>
      )}
      <LogoutButton size={isMobileView ? "small" : "large"} />
    </>
  );
}

export default function GeneralLayout({ children }: GeneralLayoutProps) {
  return (
    <>
      <ScrollToTop />
      <FloatingMenu>
        <FloatingMenuItems />
      </FloatingMenu>
      <Header showTagline={true} />
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <MainMenu />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}
