import React from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "@timandgareth/domain";
import { useTranslation } from "react-i18next";

interface LanguageIconProps {
  locale: string;
  size: "small" | "large";
}

function LanguageIcon({ locale, size }: LanguageIconProps) {
  const pxSize = size === "small" ? 22 : 28;
  const style = {
    width: pxSize,
    height: pxSize,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: pxSize,
  };

  let char = "🇬🇧";
  if (locale === "de") {
    char = "🇩🇪";
  }

  return <div style={style}>{char}</div>;
}

interface LanguagePickerProps {
  size: "small" | "large";
}

export default function LanguagePicker({ size }: LanguagePickerProps) {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.resolvedLanguage || DEFAULT_LOCALE;
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>();
  const handleLanguageSelect = (locale: string) => {
    i18n.changeLanguage(locale);
    setAnchorEl(undefined);
  };
  return (
    <>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size={size}
      >
        <LanguageIcon locale={currentLocale} size={size} />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {SUPPORTED_LOCALES.map((locale, index) => (
          <MenuItem key={index} onClick={() => handleLanguageSelect(locale)}>
            <ListItemIcon>
              <LanguageIcon locale={locale} size={size} />
            </ListItemIcon>
            <ListItemText>{t(`language.${locale}`)}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
