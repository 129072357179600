import React from "react";
import WeddingContent from "./wedding/Content";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import RecipeList from "./recipes/RecipeList";
import RecipeDetail from "./recipes/RecipeDetail";
import GeneralLayout from "./layouts/GeneralLayout";
import {
  AdminLayout,
  CreateUser,
  UserList,
  DatabaseMaintenance,
} from "./admin";
import Home from "./Home";
import VideoList from "./videos/VideoList";
import PlacesList from "./places/PlacesList";
import ErrorView from "./ErrorView";
import useApi from "./hooks/useApi";
import Photos from "./photos/Photos";
import TimsPhotoArchive from "./photos/TimsPhotoArchive";
import TimsPhotoArchiveAlbum from "./photos/TimsPhotoArchiveAlbum";
import PhotosAlbum from "./photos/PhotosAlbum";
import TodoListList from "./admin/TodoListList";

function ErrorBoundary() {
  let error = useRouteError() as Error;
  console.trace(error);
  return <ErrorView error={error} />;
}

export default function Content() {
  const {
    getContactInfos,
    getPhotoAlbum,
    listPhotoAlbums,
    getPlaces,
    getRecipe,
    getRecipes,
    getVideos,
    listUsers,
    listTodoLists,
  } = useApi();

  // TODO add 500 and 404 page

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <GeneralLayout>
          <Outlet />
        </GeneralLayout>
      ),
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "",
          element: <Home />,
          loader: () => getContactInfos(),
        },
        {
          path: "/wedding",
          element: <WeddingContent />,
        },
        {
          path: "/photos",
          element: <Photos />,
          loader: ({ request }) => {
            const tags = new URL(request.url).searchParams.getAll("tags");
            return listPhotoAlbums({ tags });
          },
        },
        {
          path: "/tims-photo-archive",
          element: <TimsPhotoArchive />,
          loader: ({ request }) => {
            const tags = new URL(request.url).searchParams.getAll("tags");
            return listPhotoAlbums({ timsArchive: true, tags });
          },
        },
        {
          path: "/photos/:albumYear/:albumFolder",
          element: <PhotosAlbum />,
          loader: ({ params }) =>
            getPhotoAlbum(params.albumYear!, params.albumFolder!),
        },
        {
          path: "/tims-photo-archive/:albumYear/:albumFolder",
          element: <TimsPhotoArchiveAlbum />,
          loader: ({ params }) =>
            getPhotoAlbum(params.albumYear!, params.albumFolder!),
        },
        {
          path: "/videos",
          element: <VideoList />,
          loader: () => getVideos(),
        },
        {
          path: "/recipes",
          element: <RecipeList />,
          loader: () => getRecipes(),
        },
        {
          path: "/recipes/:recipeKey",
          element: <RecipeDetail />,
          loader: ({ params }) => getRecipe(params.recipeKey!),
        },
        {
          path: "/places",
          element: <PlacesList />,
          loader: () => getPlaces(),
        },
      ],
    },
    {
      path: "admin",
      element: (
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      ),
      children: [
        {
          path: "users/create",
          element: <CreateUser />,
        },
        {
          path: "users",
          element: <UserList />,
          loader: async () => {
            const users = await listUsers();
            return { users };
          },
        },
        {
          path: "database",
          element: <DatabaseMaintenance />,
        },
        {
          path: "todos",
          element: <TodoListList />,
          loader: () => listTodoLists(),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
