import React, { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TodoList } from "@timandgareth/domain";
import { useLoaderData } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useApi } from "../hooks";

interface CreateListDialogProps {
  open: boolean;
  handleCancel: () => void;
  handleCreate: (title: string) => void;
}

function CreateListDialog({
  open,
  handleCancel,
  handleCreate,
}: CreateListDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          const title = formJson.title;
          handleCreate(title);
        },
      }}
    >
      <DialogTitle>Create Todo List</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter the list title below</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="titleField"
          name="title"
          label="Title"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="submit">Create</Button>
      </DialogActions>
    </Dialog>
  );
}

function TodoListView({ id: listId, title, todos }: TodoList) {
  const { createTodo, deleteTodoList, deleteTodo } = useApi();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newTodoSummary, setNewTodoSummary] = useState("");
  return (
    <Paper key={listId} elevation={5} sx={{ padding: 3, borderRadius: 2 }}>
      <Typography variant="h3" sx={{ marginTop: 0 }}>
        <span>{title}</span>
        <IconButton
          aria-label="delete list"
          size="medium"
          onClick={() => deleteTodoList(listId)}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Typography>
      <List dense>
        {todos.map(({ id: todoId, summary, due }) => (
          <ListItem
            key={todoId}
            secondaryAction={
              <IconButton
                aria-label="delete todo"
                size="small"
                onClick={() => deleteTodo(listId, todoId!)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <ListItemText primary={summary} secondary={due} />
          </ListItem>
        ))}
      </List>
      <IconButton
        aria-label="create list"
        size="small"
        onClick={() => setShowCreateForm(true)}
      >
        <AddCircleIcon fontSize="inherit" />
      </IconButton>
      {showCreateForm && (
        <Grid container>
          <Grid item xs={10}>
            <TextField
              fullWidth
              value={newTodoSummary}
              onChange={(e) => setNewTodoSummary(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={async () => {
                await createTodo(listId, newTodoSummary);
                setShowCreateForm(false);
              }}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default function () {
  const { createTodoList } = useApi();
  const todoLists = useLoaderData() as TodoList[];
  const [createListDialogOpen, setCreateListDialogOpen] = useState(false);
  return (
    <>
      <CreateListDialog
        open={createListDialogOpen}
        handleCancel={() => setCreateListDialogOpen(false)}
        handleCreate={async (title: string) => {
          await createTodoList(title);
          setCreateListDialogOpen(false);
        }}
      />
      <Typography variant="h2">Todos</Typography>
      <Grid container>
        {todoLists.map((todoList) => (
          <Grid item xs={6}>
            <TodoListView {...todoList} />
          </Grid>
        ))}
      </Grid>
      <IconButton
        aria-label="create list"
        size="large"
        onClick={() => setCreateListDialogOpen(true)}
      >
        <AddCircleIcon fontSize="inherit" />
      </IconButton>
    </>
  );
}
