import { Box } from "@mui/material";

interface FloatingMenuProps {
  children: JSX.Element;
}

export default function FloatingMenu({ children }: FloatingMenuProps) {
  return (
    <Box
      sx={{
        zIndex: 999,
        position: "fixed",
        right: {
          xs: 5,
          md: 15,
        },
        top: {
          xs: 5,
          md: 15,
        },
      }}
    >
      {children}
    </Box>
  );
}
