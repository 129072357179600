import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      language: {
        de: "German",
        en: "English",
      },
      "login.hello": "Oh hello!",
      "login.introduction":
        "We're happy you made it here. Please provide your email to get started.",
      "login.code-sent":
        "We sent a secret one-time code to your email inbox 📮. Please enter it below.",
      "login.wrong-answer_one":
        "Uh-oh. That wasn't the right answer. You have {{count}} attempt remaining.",
      "login.wrong-answer_other":
        "Uh-oh. That wasn't the right answer. You have {{count}} attempts remaining.",
      "login.code-expired":
        "The previous code expired. Please request a new code.",
      "login.no-attempts-remaining":
        "You ran out of attempts. Please request a new code.",
      "login.submit": "Submit",
      "login.is-private-computer": "This is a private computer",
      "login.form.email": "Email",
      "login.form.code": "Code",
      "request-access.form.email": "Email",
      "request-access.form.introduction": "Introduction",
      "request-access.submit": "Submit",
      "request-access.back": "back",
      "request-access.back-to-start": "back to start",
      "request-access.title":
        "Sorry, we don't have your email in our access list (yet)...",
      "request-access.ask": "Can you please quickly introduce yourself?",
      "request-access.reason":
        "Why are we asking? If your email clearly includes your name, we'll probably be able to identify you. But if your email is <email>sunnysideup73@web.de</email>, we might need a little bit of context.",
      "request-access.thank-you": "Thank you! We will get back to you!",
      "request-access.close-window": "You can close this window now.",
      "login-wrapper.authenticating": "Authenticating...",
      "menu.home": "Home",
      "menu.wedding": "Wedding",
      "menu.photos": "Photos",
      "menu.tims-photo-archive": "Tim's Photo Archive",
      "menu.videos": "Videos",
      "menu.recipes": "Recipes",
      "menu.places": "Places",
      "home.contact": "Contact",
      "home.welcome":
        "Welcome to Tim & Gareth's little website. Please choose one of the menu items above.",
      "home.email.both": "both",
      "photos.filter-by-tags": "Filter by tags",
      "photos.showing-albums_one": "Showing {{count}} album",
      "photos.showing-albums_other": "Showing {{count}} albums",
      "photos.showing-albums-filtered_one":
        "Showing {{actual}} out of {{count}} album",
      "photos.showing-albums-filtered_other":
        "Showing {{actual}} out of {{count}} albums",
      "photos.tags.show-all": "Show all",
      "photos.tags.clear-all": "Clear all",
      "photos.back-to-list": "Back to album list",
      "photos.total-count_one": "Total: {{count}} photo",
      "photos.total-count_other": "Total: {{count}} photos",
      "wedding.story": "The Story",
      "wedding.story.body": `<paragraph>
      Tired of non-committal, vain men in Vancouver, Gareth decided to try his
      luck in bettering his career and finding love in Germany in September
      2018. He learned the language back in 2008 for two semesters. The
      experience would improve his German, he thought. Late one Friday
      evening, after a colleague stood him up for karaoke night at an Irish
      pub in Essen, Gareth annoyingly trudged down to the train station,
      “Martinstraße” (Martin Street), to go back to Bochum, where he was
      staying. A tall, blond, handsome man entered the station and walked by
      Gareth, who was seated on a bench while waiting for the U11 tram.
    </paragraph>
    <paragraph>
      Gareth had been feeling invisible in Germany since he arrived because
      not many people smiled back at him when they made eye contact. He was
      either met with stares or indifference with the occasional hellos from
      nice strangers. But even with an already spoiled evening, Gareth decided
      to try again and smiled at the tall, blond man as he passed by – hoping
      for some reciprocation like he was used to back home. To his delight,
      the man smiled at him too. Just a few minutes later, the tram arrived
      and the man sat across from Gareth, occasionally exchanging smiles and
      giggles, yet no words were exchanged. As the tram pulled into Essen main
      station, Gareth said “Have a great evening” and left for the next train
      to Bochum main station. The encounter left him happy - that at least he
      felt seen, finally.
    </paragraph>
    <paragraph>
      Back in Bochum main station, as Gareth was going down the stairs to take
      the 308 tram back to his place, he saw the man again right across the
      platform. They both waved at each other. With the help of technology,
      the two men were able to connect in a few minutes. Two hours later, they
      were talking on the phone and two evenings later, they had their first
      date (at the DB Lounge, ThyssenKrupp Mansion, and a hotpot place). Two
      weeks later, Gareth met his whole family. If you didn’t see that coming,
      that tall blond guy was Tim… and now they are married!
    </paragraph>`,
      "wedding.to-photos": "To the photos",
      "tagline-1": "Fostering human connection since 1986",
      "tagline-2": "Happy ever after since 2019",
    },
  },
  de: {
    translation: {
      language: {
        de: "Deutsch",
        en: "Englisch",
      },
      "login.hello": "Oh, hallo!",
      "login.introduction":
        "Wir freuen uns, dass du hier bist. Bitte gib deine E-Mail-Adresse an, um loszulegen.",
      "login.code-sent":
        "Wir haben einen Einmal-Code an deine Email-Adresse geschickt 📮. Bitte gebe den Code aus der Email unten ein.",
      "login.wrong-answer_one":
        "Oh oh. Das war nicht der richtige Code. Du hast noch {{count}} Versuch übrig.",
      "login.wrong-answer_other":
        "Oh oh. Das war nicht der richtige Code. Du hast noch {{count}} Versuche übrig.",
      "login.code-expired":
        "The previous code expired. Please request a new code.",
      "login.no-attempts-remaining":
        "Du hast leider die maximale Zahl an Versuchen erreicht. Bitte fordere einen neuen Code an.",
      "login.submit": "Abschicken",
      "login.is-private-computer": "Ich bin an einem privaten Computer",
      "login.form.email": "E-Mail",
      "login.form.code": "Code",
      "request-access.form.email": "E-Mail",
      "request-access.form.introduction": "Wer bist Du?",
      "request-access.submit": "Abschicken",
      "request-access.back": "zurück",
      "request-access.back-to-start": "zurück",
      "request-access.title":
        "Wir haben deine E-Mail-Adresse leider nicht auf unserer Liste",
      "request-access.ask":
        "Wir können dich leider erst reinlassen, wenn wir deine Adresse in unserer Liste haben. Bitte stelle dich kurz vor und schicke das Formular ab.",
      "request-access.reason":
        "Warum das Ganze? Naja, wenn deine E-Mail-Adresse <email>hans.mueller@web.de</email> ist, wissen wir wer anfragt. Aber wenn Deine E-Mail-Adresse <email>kleine.hexe73@web.de</email> ist, brauchen wir eine kleine Hilfestellung.",
      "request-access.thank-you":
        "Danke dir! Wir schalten dich sobald wie möglich frei und geben dir Bescheid.",
      "request-access.close-window": "Du kannst dieses Fenster nun schließen.",
      "login-wrapper.authenticating": "Wir loggen dich ein...",
      "menu.home": "Home",
      "menu.wedding": "Hochzeit",
      "menu.photos": "Fotos",
      "menu.tims-photo-archive": "Tim's Foto-Archiv",
      "menu.videos": "Videos",
      "menu.recipes": "Rezepte",
      "menu.places": "Orte",
      "home.contact": "Kontakt",
      "home.welcome": "Willkommen auf unserer kleinen Webseite.",
      "home.email.both": "beide",
      "photos.filter-by-tags": "Filtere nach Kategorie",
      "photos.showing-albums_one": "{{count}} Album",
      "photos.showing-albums_other": "{{count}} Alben",
      "photos.showing-albums-filtered_one": "{{actual}} Album",
      "photos.showing-albums-filtered_other": "{{actual}} von {{count}} Alben",
      "photos.tags.show-all": "Zeige alle",
      "photos.tags.clear-all": "Alle Filter löschen",
      "photos.back-to-list": "Zurück zur Album-Liste",
      "photos.total-count_one": "{{count}} Foto",
      "photos.total-count_other": "{{count}} Fotos",
      "wedding.story": "Die Geschichte",
      "wedding.story.body": `<paragraph>
      Müde von unverbindlichen, eitlen Männern in Vancouver beschloss Gareth, im September 2018 sein Glück zu versuchen, seine Karriere zu verbessern und die Liebe in Deutschland zu finden. Er hatte die Sprache 2008 für zwei Semester gelernt. Die Erfahrung würde sein Deutsch verbessern, dachte er. An einem späten Freitagabend, nachdem ein Kollege ihn für einen Karaokeabend in einem Irish Pub in Essen versetzt hatte, trottete Gareth ärgerlich zum Bahnhof „Martinstraße“, um zurück nach Bochum zu fahren, wo er wohnte. Ein großer, blonder, gutaussehender Mann betrat den Bahnhof und ging an Gareth vorbei, der auf einer Bank saß und auf die U11 wartete.
    </paragraph>
    <paragraph>
      Gareth fühlte sich seit seiner Ankunft in Deutschland unsichtbar, weil ihm nicht viele Leute ein Lächeln erwiderten, wenn sie Blickkontakt hatten. Er wurde entweder angestarrt oder mit Gleichgültigkeit konfrontiert, mit gelegentlichen Grüßen von netten Fremden. Aber trotz des bereits verdorbenen Abends beschloss Gareth, es noch einmal zu versuchen, und lächelte den großen, blonden Mann an, als er vorbeiging – in der Hoffnung auf eine Erwiderung, wie er es von zu Hause gewohnt war. Zu seiner Freude lächelte der Mann ihn ebenfalls an. Nur wenige Minuten später kam die Straßenbahn, und der Mann setzte sich Gareth gegenüber, wobei sie gelegentlich Lächeln und Kichern austauschten, jedoch keine Worte. Als die Straßenbahn in den Essener Hauptbahnhof einfuhr, sagte Gareth „Schönen Abend noch“ und ging zum nächsten Zug zum Bochumer Hauptbahnhof. Die Begegnung machte ihn glücklich – zumindest fühlte er sich endlich gesehen.
    </paragraph>
    <paragraph>
      Zurück am Bochumer Hauptbahnhof, als Gareth die Treppe hinunterging, um die 308 zurück zu seiner Unterkunft zu nehmen, sah er den Mann wieder auf der anderen Seite des Bahnsteigs. Sie winkten sich beide zu. Mit Hilfe der Technik konnten sich die beiden Männer in wenigen Minuten verbinden. Zwei Stunden später telefonierten sie und zwei Abende später hatten sie ihr erstes Date (in der DB Lounge, der ThyssenKrupp Villa und einem Hotpot-Lokal). Zwei Wochen später traf Gareth seine ganze Familie. Falls du es nicht erwartet hast, dieser große, blonde Mann war Tim… und jetzt sind sie verheiratet!
    </paragraph>`,
      "wedding.to-photos": "Zu den Fotos",
      "tagline-1": "Bringen seit 1986 Menschen zusammen",
      "tagline-2": "Glücklich zusammen seit 2019",
      photos: {
        tags: {
          wedding: "hochzeit",
          trips: "reisen",
          asia: "asien",
          "bubbly-brekkie": "sektfrühstück",
          cruise: "kreuzfahrt",
          "wedding-day": "hochzeitstag",
          california: "kalifornien",
          canada: "kanada",
          germany: "deutschland",
          "north-america": "nordamerika",
          philippines: "philippinen",
          oceania: "ozeanien",
          australia: "australien",
          singapore: "singapur",
          "new-zealand": "neuseeland",
          europe: "europa",
          "south-korea": "südkorea",
          carribean: "karibik",
          indonesia: "indonesien",
          cambodia: "kambodscha",
          denmark: "dänemark",
          italy: "italien",
          uk: "grossbritannien",
        },
      },
    },
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
  });

export default i18n;
