import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useApi } from "../hooks";

export default function () {
  const [loading, setLoading] = useState<boolean>(false);
  const { executeDatabaseMigrations } = useApi();

  async function doMigrations() {
    setLoading(true);
    try {
      await executeDatabaseMigrations();
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Typography variant="h2">Database maintenance</Typography>
      <Button
        variant="outlined"
        disabled={loading}
        onClick={() => doMigrations()}
      >
        Execute migrations
      </Button>
    </>
  );
}
