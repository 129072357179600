import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function GermanTranslationDisclaimer() {
  const { i18n } = useTranslation();
  if (i18n.language !== "de") {
    return null;
  }
  return (
    <Typography>
      <i>
        Dieser Bereich ist leider noch nicht ins Deutsche übersetzt. Wir
        arbeiten dran!
      </i>
    </Typography>
  );
}
