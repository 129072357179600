import React, { useState } from "react";
import {
  Box,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { Image } from "mui-image";
import Carousel from "react-material-ui-carousel";
import MapIcon from "@mui/icons-material/Map";
import LaunchIcon from "@mui/icons-material/Launch";
import { uniq } from "lodash";
import { Place, PlaceCategory } from "@timandgareth/domain";
import { useLoaderData } from "react-router-dom";
import { LocationMap } from "../components";
import MapOverlay from "./MapOverlay";
import { useTranslation } from "react-i18next";

const chipColors: Record<string, string> = {
  Bochum: "#b7c3f7",
  Essen: "#f7c4b7",
  Hattingen: "#cdfac0",
  Herne: "#e3ac07",
};

const defaultChipColor = "#d99ed0";

function getChipColor(city: string): string {
  if (chipColors[city]) {
    return chipColors[city];
  }
  return defaultChipColor;
}

type PlaceActionsProps = Pick<Place, "url" | "googleMapsUrl" | "position"> & {
  openInMap: () => void;
};

function PlaceActions({
  url,
  googleMapsUrl,
  position,
  openInMap,
}: PlaceActionsProps) {
  if (!url && !googleMapsUrl && !position) {
    return <></>;
  }

  return (
    <Box>
      <Tooltip title="Show on Map" arrow placement="top">
        <IconButton onClick={openInMap} aria-label="Show on Map" size="small">
          <MapIcon />
        </IconButton>
      </Tooltip>
      {googleMapsUrl && (
        <Tooltip title="Open in Google Maps" arrow placement="top">
          <IconButton
            onClick={() => window.open(googleMapsUrl)}
            aria-label="Open in Google Maps"
            size="small"
          >
            <Icon>
              <img src="/img/gmaps.svg" />
            </Icon>
          </IconButton>
        </Tooltip>
      )}
      {url && (
        <Tooltip title="Open Website" arrow placement="top">
          <IconButton
            onClick={() => window.open(url)}
            aria-label="Open Website"
            size="small"
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}

const categoryTitles: Partial<Record<PlaceCategory, string>> = {
  [PlaceCategory.EAT]: "Restaurants & Cafés",
  [PlaceCategory.DRINK]: "Pubs & Bars",
  [PlaceCategory.SIGHT]: "Sights & Activities",
};

interface PointsOfInterestProps {
  places: Place[];
  focusMapLocation: (locationKey: string) => void;
}

function PointsOfInterest({ places, focusMapLocation }: PointsOfInterestProps) {
  const allCategories = uniq(places.map((place) => place.category));
  const allCities = uniq(places.map((place) => place.city));

  const [selectedCategories, setSelectedCategories] =
    React.useState<string[]>(allCategories);
  const [selectedCities, setSelectedCities] =
    React.useState<string[]>(allCities);

  const handleCategoryChange = (
    event: SelectChangeEvent<typeof selectedCategories>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCitiesChange = (
    event: SelectChangeEvent<typeof selectedCities>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedCities(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const imageHeight = 200;

  return (
    <Grid container spacing={2} sx={{ marginTop: "0.1rem" }}>
      <Grid item xs={12}>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="types-label">Categories</InputLabel>
          <Select
            labelId="types-label"
            id="demo-multiple-chip"
            multiple
            value={selectedCategories}
            onChange={handleCategoryChange}
            input={
              <OutlinedInput id="select-multiple-types" label="Categories" />
            }
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: string) => (
                  <Chip
                    key={value}
                    label={categoryTitles[value as PlaceCategory]}
                  />
                ))}
              </Box>
            )}
          >
            {allCategories.map((type) => (
              <MenuItem key={type} value={type}>
                {categoryTitles[type]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="cities-label">Cities</InputLabel>
          <Select
            labelId="cities-label"
            multiple
            value={selectedCities}
            onChange={handleCitiesChange}
            input={<OutlinedInput id="select-multiple-cities" label="Cities" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
          >
            {allCities.map((city) => (
              <MenuItem key={city} value={city}>
                {city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {places
        .filter(
          (place) =>
            selectedCategories.includes(place.category) &&
            selectedCities.includes(place.city)
        )
        .map(
          (
            { title, city, description, images, url, googleMapsUrl, position },
            index
          ) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Paper
                elevation={0}
                sx={{
                  borderRadius: 3,
                  border: "1px solid black",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  sx={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                  }}
                >
                  {images.length > 1 && (
                    <Carousel
                      autoPlay={false}
                      animation="slide"
                      duration={150}
                      navButtonsAlwaysVisible={true}
                      swipe={true}
                      sx={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                      }}
                      indicators={false}
                    >
                      {images?.map((image, index) => (
                        <Image
                          src={image}
                          duration={0}
                          key={index}
                          style={{
                            borderTopLeftRadius: 10,
                            borderTopRightRadius: 10,
                            height: imageHeight,
                            minHeight: imageHeight,
                            maxHeight: imageHeight,
                          }}
                        />
                      ))}
                    </Carousel>
                  )}
                  {images.length === 1 && (
                    <Image
                      src={images[0]}
                      duration={0}
                      key={index}
                      style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        height: imageHeight,
                        minHeight: imageHeight,
                        maxHeight: imageHeight,
                      }}
                    />
                  )}
                </CardMedia>
                <CardContent sx={{ padding: 1 }}>
                  <Typography gutterBottom variant="h4" component="div">
                    <span>{title}</span>
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    {description}
                  </Typography>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 1,
                    m: 1,
                    alignContent: "flex-end",
                    marginTop: "auto",
                  }}
                >
                  <Chip
                    label={city}
                    size="small"
                    sx={{ backgroundColor: getChipColor(city) }}
                  />
                  <PlaceActions
                    url={url}
                    position={position}
                    googleMapsUrl={googleMapsUrl}
                    openInMap={() => focusMapLocation(title)}
                  />
                </CardActions>
              </Paper>
            </Grid>
          )
        )}
    </Grid>
  );
}

interface OverviewMapProps {
  focusedLocationKey?: string | undefined;
  places: Place[];
}

function OverviewMap({ focusedLocationKey, places }: OverviewMapProps) {
  return (
    <LocationMap places={places} focusedLocationKey={focusedLocationKey} />
  );
}

export default function PlacesList() {
  const { t } = useTranslation();
  const places = useLoaderData() as Place[];
  const [focusedLocationKey, setFocusedLocationKey] = useState<
    string | undefined
  >();
  function focusMapLocation(key: string) {
    setFocusedLocationKey(key);
  }
  return (
    <Grid container>
      <Grid item md={12}>
        <Typography variant="h2">{t("menu.places")}</Typography>
        <MapOverlay
          places={places}
          focusedLocationKey={focusedLocationKey}
          onClose={() => setFocusedLocationKey(undefined)}
        />
        <PointsOfInterest places={places} focusMapLocation={focusMapLocation} />
        <Typography variant="h3">Overview map</Typography>
        <OverviewMap places={places} focusedLocationKey={focusedLocationKey} />
      </Grid>
    </Grid>
  );
}
