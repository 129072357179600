import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import { Menu } from "../components";

export default function AdminMenu() {
  const menuItems = [
    {
      url: "/admin/users",
      title: "Users",
      icon: <GroupIcon />,
    },
    {
      url: "/admin/database",
      title: "Database maintenance",
      icon: <BuildCircleIcon />,
    },
  ];
  return <Menu menuItems={menuItems} />;
}
