import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../hooks";

interface LogoutButtonProps {
  size: "small" | "medium" | "large";
}

export default function LogoutButton({ size }: LogoutButtonProps) {
  const { logout } = useAuth();
  async function logoutAndGoToRoot() {
    logout();
    window.location.assign("/");
  }
  return (
    <IconButton onClick={() => logoutAndGoToRoot()} size={size}>
      <LogoutIcon />
    </IconButton>
  );
}
