import React from "react";
import { Video } from "@timandgareth/domain";
import { Box, Grid, Typography } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import { orderBy, groupBy, keys, identity } from "lodash";
import { useTranslation } from "react-i18next";
import { GermanTranslationDisclaimer } from "../components";

export default function VideoList() {
  const { t } = useTranslation();
  const videos = useLoaderData() as Video[];
  const groupedVideos = groupBy(videos, "year");
  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Box>
            <Typography variant="h2">{t("menu.videos")}</Typography>
            <GermanTranslationDisclaimer />
            {orderBy(keys(groupedVideos), identity, "desc").map((year) => (
              <Box key={year}>
                <Typography variant="h3">{year}</Typography>
                <ul>
                  {orderBy(groupedVideos[year], "title", "asc").map(
                    ({ title, description, url }, index) => (
                      <li key={index} style={{ marginTop: "8px" }}>
                        <a href={url} target="_blank">
                          <strong>{title}</strong>
                        </a>
                        <br />
                        {description}
                      </li>
                    )
                  )}
                </ul>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
