import React from "react";
import PhotoAlbumList from "./PhotoAlbumList";
import { useTranslation } from "react-i18next";

export default function () {
  const { t } = useTranslation();
  return (
    <PhotoAlbumList
      title={t("menu.tims-photo-archive")}
      baseUrl="/tims-photo-archive"
    />
  );
}
