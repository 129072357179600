import React from "react";
import { OpenPhotoAlbum } from "@timandgareth/domain";
import Lightbox from "yet-another-react-lightbox";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { useAuth } from "../hooks";

interface PhotoFullscreenViewProps {
  open: boolean;
  album: OpenPhotoAlbum;
  handleClose: () => void;
  photoIndex: number | undefined;
}

export default function PhotoFullscreenView({
  open,
  album,
  photoIndex,
  handleClose,
}: PhotoFullscreenViewProps) {
  const { jwt } = useAuth();

  const slides = album.photos.map(({ filename }) => ({
    src: `${process.env.REACT_APP_SERVER_BASE_URL}/photos/v2/albums/${album.url}/photos/${filename}?jwt=${jwt}`,
  }));

  return (
    <Lightbox
      open={open}
      close={handleClose}
      slides={slides}
      index={photoIndex}
      carousel={{ preload: 1 }}
      plugins={[Slideshow, Fullscreen, Zoom, Download]}
      slideshow={{
        delay: 5000,
      }}
    />
  );
}
