import React from "react";
import { Grid } from "@mui/material";

interface RootLayoutProps {
  children: JSX.Element;
}

export default function RootLayout({ children }: RootLayoutProps) {
  return (
    <Grid
      container
      sx={{
        maxWidth: "1200px",
        margin: "auto",
      }}
    >
      <Grid item md={12} sx={{ marginLeft: "20px", marginRight: "20px" }}>
        {children}
      </Grid>
    </Grid>
  );
}
