import { OpenPhotoAlbum } from "@timandgareth/domain";
import { useEffect, useState } from "react";
import PhotoFullscreenView from "./PhotoFullscreenView";
import { PhotoAlbum, RenderPhotoProps } from "react-photo-album";
import { useAuth } from "../hooks";
import { Skeleton } from "@mui/material";

export interface PhotoListProps {
  album: OpenPhotoAlbum;
}

function Photo({
  imageProps: { src, alt, style, ...restImageProps },
}: RenderPhotoProps) {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const [forceReloadKey, setForceReloadKey] = useState(0);
  useEffect(() => {
    setForceReloadKey(forceReloadKey + 1);
  }, [src]);

  const displayOptions = imageLoaded ? {} : { display: "none" };

  return (
    <>
      {!imageLoaded && (
        <div style={style}>
          <Skeleton
            variant="rounded"
            width="100%"
            height="100%"
            sx={{ bgcolor: "grey.400" }}
          />
        </div>
      )}
      <img
        key={forceReloadKey}
        src={src}
        alt={alt}
        style={{
          ...style,
          ...displayOptions,
        }}
        onLoad={() => setImageLoaded(true)}
        onError={() => setImageLoaded(true)}
        {...restImageProps}
      />
    </>
  );
}

export function PhotoList({ album }: PhotoListProps) {
  const { jwt } = useAuth();
  const [fullscreenPhotoIndex, setFullscreenPhotoIndex] = useState<
    number | undefined
  >(undefined);

  function handleFullscreenClose() {
    setFullscreenPhotoIndex(undefined);
  }

  return (
    <>
      <PhotoFullscreenView
        open={typeof fullscreenPhotoIndex !== "undefined"}
        album={album}
        photoIndex={fullscreenPhotoIndex}
        handleClose={handleFullscreenClose}
      />
      <PhotoAlbum
        layout="masonry"
        onClick={({ index }) => setFullscreenPhotoIndex(index)}
        photos={album.photos.map(
          ({ filename, dimensions: { thumbnail } }, index) => {
            return {
              src: `${process.env.REACT_APP_SERVER_BASE_URL}/photos/v2/albums/${album.url}/thumbnails/${filename}?jwt=${jwt}`,
              width: thumbnail.width,
              height: thumbnail.height,
            };
          }
        )}
        columns={(containerWidth) => {
          if (containerWidth >= 1200) return 5;
          if (containerWidth >= 600) return 3;
          if (containerWidth >= 300) return 2;
          return 1;
        }}
        spacing={(containerWidth) => {
          if (containerWidth >= 1200) return 15;
          if (containerWidth >= 600) return 5;
          return 2;
        }}
        renderPhoto={Photo}
        componentsProps={{
          imageProps: { loading: "eager" },
        }}
      />
    </>
  );
}
