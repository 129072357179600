import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import Header from "../Header";
import { FloatingMenu, LanguagePicker } from "../components";

interface BaseAuthFormProps {
  children: any;
}

export default function BaseAuthForm({ children }: BaseAuthFormProps) {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <FloatingMenu>
        <LanguagePicker size={isMobileView ? "small" : "large"} />
      </FloatingMenu>
      <Grid
        container
        spacing={1}
        sx={{
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Header />
        {children}
      </Grid>
    </>
  );
}
