import { createContext } from "react";

export interface JwtInfo {
  jwt: string | undefined;
  setJwt: (jwt: string | undefined) => void;
}

export const JwtContext = createContext<JwtInfo>({
  jwt: "",
  setJwt: (str) => {},
});
