import React from "react";
import { User } from "@timandgareth/domain";
import { useLoaderData, useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Button,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

export default function () {
  const { users } = useLoaderData() as { users: User[] };
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h2">Users</Typography>
      <Grid container>
        <Grid item sx={{ alignContent: "right" }}>
          <Button
            sx={{ margin: 1 }}
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => navigate("/admin/users/create")}
          >
            Create user
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ "& th, &td": { fontWeight: "bold" } }}>
              <TableCell>Username</TableCell>
              <TableCell>Emails</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(({ username, emails, roles }, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {username}
                </TableCell>
                <TableCell>
                  {emails.map((email, index) =>
                    index === 0 ? (
                      <strong>{email}</strong>
                    ) : (
                      <span>, {email}</span>
                    )
                  )}
                </TableCell>
                <TableCell>
                  {roles.map((role) => (
                    <Chip label={role} />
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
