import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import { useContext, useState } from "react";
import { AuthorizedRole, Feature } from "@timandgareth/domain";
import { AuthorizationContext } from "../AuthorizationContext";
import useApi from "../hooks/useApi";

export default function CreateUser() {
  const { createUser } = useApi();
  const { features } = useContext(AuthorizationContext);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState<string>(
    (searchParams.get("email") as string) || ""
  );
  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const [locale, setLocale] = useState<string>("en");
  const [selectedRoles, setSelectedRoles] = useState<AuthorizedRole[]>([]);

  async function create() {
    const roles = selectedRoles;
    if (roles.length === 0) {
      // nothing to do
      return;
    }
    setLoading(true);
    try {
      await createUser(username, email, roles, locale, sendEmail);
      setSuccess(true);
    } finally {
      setLoading(false);
    }
  }

  if (!features.includes(Feature.ADMIN)) {
    return <Typography>You are not authorized to access this page.</Typography>;
  }
  const assignableRoles = Object.values(AuthorizedRole).filter(
    (role) => role !== AuthorizedRole.ADMIN
  );

  function handleRoleSelection(event: SelectChangeEvent) {
    const {
      target: { value },
    } = event;
    setSelectedRoles(
      // On autofill we get a stringified value.
      (typeof value === "string" ? value.split(",") : value) as AuthorizedRole[]
    );
  }

  console.log({ email, selectedRoles, locale, sendEmail });
  return (
    <>
      <Typography variant="h3">Create user</Typography>
      <Grid container>
        <Grid item xs={12}>
          <FormGroup>
            <FormControl>
              <FormLabel required>Username</FormLabel>
              <TextField
                type="text"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value?.trim() || "");
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel required>Email</FormLabel>
              <TextField
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value?.trim() || "");
                }}
              />
            </FormControl>
            <FormControl>
              <InputLabel id="demo-multiple-name-label">Roles</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={selectedRoles as unknown as string}
                onChange={handleRoleSelection}
                input={<OutlinedInput label="Name" />}
              >
                {assignableRoles.map((role, index) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              label="Send email?"
              control={
                <Checkbox
                  checked={sendEmail}
                  onChange={(event) => setSendEmail(event.target.checked)}
                />
              }
            />
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Language
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="en"
                name="radio-buttons-group"
                value={locale}
                onChange={(event) => setLocale(event.target.value)}
              >
                <FormControlLabel
                  value="en"
                  control={<Radio />}
                  label="English"
                />
                <FormControlLabel
                  value="de"
                  control={<Radio />}
                  label="German"
                />
              </RadioGroup>
            </FormControl>
            <Button
              sx={{ marginTop: 2 }}
              variant="contained"
              onClick={() => create()}
              disabled={loading || success}
              endIcon={
                loading ? (
                  <CircularProgress size={12} />
                ) : success ? (
                  <CheckIcon />
                ) : undefined
              }
            >
              Allow
            </Button>
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
}
