import React, { useState } from "react";
import "./App.css";
import Content from "./Content";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import RootLayout from "./layouts/RootLayout";
import { ErrorBoundary } from "react-error-boundary";
import ErrorView from "./ErrorView";
import { JwtContext } from "./hooks/JwtContext";
import { LoginWrapper } from "./auth";

function fallbackRender({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: any;
}) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return <ErrorView error={error} />;
}

const FONT_HEADING = "'Yeseva One', cursive;";
const FONT_TEXT = ["'Slabo 27px'", "serif"].join(",");

function App() {
  const [jwt, setJwt] = useState<string | undefined>(
    window.localStorage.getItem("jwt") || undefined
  );
  console.log(`Environment = ${process.env.REACT_APP_ENVIRONMENT}`);
  const defaultHeadingStyle = {
    fontFamily: FONT_HEADING,
  };
  const defaultTextStyle = {
    fontFamily: FONT_TEXT,
  };
  const theme = createTheme({
    typography: {
      h1: defaultHeadingStyle,
      h2: defaultHeadingStyle,
      h3: defaultHeadingStyle,
      h4: defaultHeadingStyle,
      h5: defaultHeadingStyle,
      h6: defaultHeadingStyle,
      ...defaultTextStyle,
    },
    palette: {
      primary: {
        light: "#202020",
        main: "#000000",
        dark: "#000000",
        contrastText: "#FFFFFF",
      },
    },
  });
  theme.typography.h1 = {
    ...theme.typography.h1,
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "4rem",
    },
  };
  theme.typography.h2 = {
    ...theme.typography.h2,
    marginTop: "4rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  };
  theme.typography.h3 = {
    ...theme.typography.h3,
    marginTop: "2rem",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6rem",
    },
  };
  theme.typography.h4 = {
    ...theme.typography.h4,
    marginTop: "1.5rem",
    marginBottom: "0.3rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.0rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.3rem",
    },
  };
  theme.typography.h5 = {
    ...theme.typography.h5,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem",
    },
  };
  theme.typography.body1 = {
    ...theme.typography.body1,
    lineHeight: "1.3rem",
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <RootLayout>
          <ErrorBoundary fallbackRender={fallbackRender}>
            <JwtContext.Provider
              value={{
                jwt,
                setJwt: (newJwt: string | undefined) => {
                  if (!newJwt) {
                    window.localStorage.removeItem("jwt");
                  } else {
                    window.localStorage.setItem("jwt", newJwt);
                  }
                  setJwt(newJwt);
                },
              }}
            >
              <LoginWrapper>
                <Content />
              </LoginWrapper>
            </JwtContext.Provider>
          </ErrorBoundary>
        </RootLayout>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
