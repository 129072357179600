import React, { useContext, useEffect } from "react";
import { isExpired } from "./../util/authToken";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { JwtContext } from "./JwtContext";

interface Auth {
  jwt: string | undefined;
  jwtRefreshInProgress: boolean;
  setJwt: (jwt: string) => void; // TODO: should only be used by the login wrapper, if anything.
  removeJwt: () => void;
  logout: () => void;
}

async function getRefreshedAuthToken(authToken: string): Promise<string> {
  const { refreshToken } = jwtDecode(authToken) as {
    refreshToken: string | undefined;
  };
  if (!refreshToken) {
    throw new Error("No refresh token. Unable to refresh.");
  }
  if (isExpired(refreshToken)) {
    throw new Error("Refresh token is expired. Unable to refresh.");
  }
  const refreshResponse = await fetch(
    process.env.REACT_APP_SERVER_BASE_URL +
      `/auth/access-tokens/refresh?refreshToken=${refreshToken}`
  );
  if (refreshResponse.status !== 200) {
    throw new Error("Non-OK response code from server for refresh request.");
  }
  const { jwt } = await refreshResponse.json();
  if (!jwt || isExpired(jwt)) {
    throw new Error(
      "Refresh unsuccessful: refreshed auth token not given or expired."
    );
  }
  return jwt;
}

export function getRefreshTimeout(authToken?: string): number | undefined {
  if (!authToken) {
    // non-existant tokens cannot be refreshed
    return undefined;
  }
  const jwt = jwtDecode<JwtPayload>(authToken);
  if (!jwt || !jwt.exp) {
    return undefined;
  }
  const expiryTimestamp = jwt.exp * 1000;
  const now = Date.now();
  if (now > expiryTimestamp) {
    // already expired
    return undefined;
  }
  return Math.round((expiryTimestamp - now) / 2.0);
}

let refreshScheduled: boolean = false;
let refreshInProgress: boolean = false;

// TODO handle multi-window refresh
export default function useAuth(): Auth {
  const { jwt, setJwt } = useContext(JwtContext);

  function refreshAuthToken() {
    refreshScheduled = false;
    if (refreshInProgress) {
      console.log("Another refresh is in progress. Returning early.");
      return;
    }
    if (!jwt) {
      console.error("No JWT present. Cannot refresh.");
      return;
    }
    refreshInProgress = true;
    console.log("Initiating refresh...");
    getRefreshedAuthToken(jwt)
      .then((refreshedAuthToken) => {
        setJwt(refreshedAuthToken);
        console.log("JWT refreshed successfully.");
      })
      .catch((err) => {
        console.log(`Error refreshing auth token`, err);
      })
      .finally(() => {
        refreshInProgress = false;
      });
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (refreshScheduled) {
      return;
    }
    const refreshTimeout = getRefreshTimeout(jwt);
    if (refreshTimeout) {
      refreshScheduled = true;
      console.log(`Scheduling jwt refresh in ${refreshTimeout / 1000.0}s`);
      timeoutId = setTimeout(refreshAuthToken, refreshTimeout);
    }
    return () => {
      if (timeoutId) {
        console.log("XXX Clearing timeout...");
        clearTimeout(timeoutId);
        refreshScheduled = false;
      }
    };
  }, [jwt]);

  let exposedJwt = jwt;

  if (jwt && isExpired(jwt)) {
    refreshAuthToken();
    setJwt(undefined);
    exposedJwt = undefined;
  }

  return {
    jwt,
    jwtRefreshInProgress: refreshInProgress,
    setJwt: (jwt: string) => setJwt(jwt),
    removeJwt: () => {
      console.log("removeJwt() called");
      setJwt(undefined);
    },
    logout: () => {
      setJwt(undefined);
    },
  };
}
