import React from "react";
import { OpenPhotoAlbum } from "@timandgareth/domain";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { useNavigate, useLoaderData } from "react-router-dom";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { PhotoList } from "./PhotoList";
import { useTranslation } from "react-i18next";
import AlbumTitle from "./AlbumTitle";

interface PhotoAlbumViewProps {
  baseUrl: string;
}

export default function PhotoAlbumView({ baseUrl }: PhotoAlbumViewProps) {
  const { t } = useTranslation();
  const album = useLoaderData() as OpenPhotoAlbum;
  const navigate = useNavigate();

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Button
          onClick={() => navigate(baseUrl)}
          startIcon={<KeyboardArrowLeftIcon />}
        >
          {t("photos.back-to-list")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ marginTop: 3 }}>
          <AlbumTitle {...album} />
        </Typography>
        <Typography>
          <i>{t("photos.total-count", { count: album.photos.length })}</i>
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <PhotoList album={album} />
      </Grid>

      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Box>
          <Button
            onClick={() => navigate(baseUrl)}
            startIcon={<KeyboardArrowLeftIcon />}
          >
            {t("photos.back-to-list")}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
