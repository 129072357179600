import React from "react";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { isValidEmail } from "../util/email";
import BaseAuthForm from "./BaseAuthForm";
import {
  AttemptChallengeResponse,
  AttemptChallengeResponseStatus,
  CreateChallengeResponse,
  DEFAULT_LOCALE,
} from "@timandgareth/domain";
import { useTranslation } from "react-i18next";

interface LoginFormProps {
  onEmailSubmit: (
    email: string,
    locale: string
  ) => Promise<CreateChallengeResponse>;
  onChallengeAnswerSubmit: (
    email: string,
    answer: string,
    includeRefreshToken: boolean
  ) => Promise<AttemptChallengeResponse>;
}

export default function LoginForm({
  onEmailSubmit,
  onChallengeAnswerSubmit,
}: LoginFormProps) {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.resolvedLanguage || DEFAULT_LOCALE;
  const [email, setEmail] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [useRefreshToken, setUseRefreshToken] = useState<boolean>(true);
  const [showEmailError, setShowEmailError] = useState(false);
  const [lastChallengeAttemptStatus, setLastChallengeAttempStatus] = useState<
    AttemptChallengeResponseStatus | undefined
  >();
  const [numberOfAttemptsRemaining, setNumberOfAttemptsRemaining] =
    useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [challengeResponseSent, setChallengeResponseSent] = useState(false);
  const answerInputRef = React.createRef<HTMLInputElement>();

  async function handleEmailSubmit(event?: React.FormEvent<HTMLFormElement>) {
    if (!isValidEmail(email)) {
      setShowEmailError(true);
      return;
    }
    setLoading(true);
    try {
      event?.preventDefault();
      const { status } = await onEmailSubmit(
        email?.trim() as string,
        currentLocale
      );
      if (status === "CHALLENGE_RESPONSE_SENT") {
        setChallengeResponseSent(true);
        setLastChallengeAttempStatus(undefined);
      }
      // TODO need to handle the other cases?
    } finally {
      setLoading(false);
    }
  }

  function reset() {
    setChallengeResponseSent(false);
  }

  async function handleAnswerSubmit(event?: React.FormEvent<HTMLFormElement>) {
    setLoading(true);
    setLastChallengeAttempStatus(undefined);
    try {
      event?.preventDefault();
      const { status, attemptsRemaining } = await onChallengeAnswerSubmit(
        email?.trim() as string,
        answer?.trim() as string,
        useRefreshToken
      );
      setLastChallengeAttempStatus(status);
      setNumberOfAttemptsRemaining(attemptsRemaining || 0);
      if (status === "WRONG_ANSWER" || status === "CHALLENGE_EXPIRED") {
        setAnswer("");
        answerInputRef.current?.focus();
        if (!attemptsRemaining) {
          // no attempts remaining
          reset();
        }
      }
      // TODO need to handle the other cases?
    } finally {
      setLoading(false);
    }
  }

  return (
    <BaseAuthForm>
      <Grid item xs={12}>
        <Typography variant="h2" sx={{ marginTop: 0 }}>
          {t("login.hello")}
        </Typography>
        {!challengeResponseSent && (
          <Typography>{t("login.introduction")}</Typography>
        )}
      </Grid>
      {lastChallengeAttemptStatus === "CHALLENGE_EXPIRED" && (
        <Grid item xs={12}>
          <Alert severity="warning">{t("login.code-expired")}</Alert>
        </Grid>
      )}
      {lastChallengeAttemptStatus === "WRONG_ANSWER" &&
        numberOfAttemptsRemaining === 0 && (
          <Grid item xs={12}>
            <Alert severity="warning">{t("login.no-attempts-remaining")}</Alert>
          </Grid>
        )}
      <Grid item xs={12}>
        <FormLabel htmlFor="email">{t("login.form.email")}</FormLabel>
        <TextField
          id="email"
          type="email"
          required
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setShowEmailError(false);
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
              handleEmailSubmit();
            }
          }}
          error={showEmailError}
          sx={{ width: 1 }}
          disabled={challengeResponseSent}
        />
      </Grid>
      {!challengeResponseSent && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => handleEmailSubmit()}
            sx={{ width: 1 }}
            disabled={loading}
          >
            {!loading && <span>{t("login.submit")}</span>}
            {loading && <CircularProgress size={24} />}
          </Button>
        </Grid>
      )}
      {challengeResponseSent && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sx={{ marginBottom: 1 }}>
            <Typography>{t("login.code-sent")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormLabel htmlFor="challenge-answer">
              {t("login.form.code")}
            </FormLabel>
            <TextField
              id="challenge-answer"
              type="text"
              required
              inputRef={answerInputRef}
              value={answer}
              onChange={(e) => {
                setAnswer(e.target.value.trim());
              }}
              sx={{ width: 1 }}
              disabled={loading}
            />
          </Grid>
          {lastChallengeAttemptStatus === "WRONG_ANSWER" && (
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Alert severity="warning">
                {t("login.wrong-answer", { count: numberOfAttemptsRemaining })}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12} sx={{ marginTop: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useRefreshToken}
                  onChange={(event) => setUseRefreshToken(event.target.checked)}
                />
              }
              label={t("login.is-private-computer")}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
            <Button
              variant="contained"
              onClick={() => handleAnswerSubmit()}
              sx={{ width: 1 }}
              disabled={loading || answer?.length === 0}
            >
              {!loading && <span>{t("login.submit")}</span>}
              {loading && <CircularProgress size={24} />}
            </Button>
          </Grid>
        </Grid>
      )}
    </BaseAuthForm>
  );
}
