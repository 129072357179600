import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  isAdminInterface?: boolean;
  showTagline?: boolean;
}

function TagLine() {
  const { t } = useTranslation();
  const taglines = [t("tagline-1"), t("tagline-2")];
  const dayOfYear = moment().dayOfYear();
  const taglineIndex = dayOfYear % taglines.length;
  return <span>~ {taglines[taglineIndex]} ~</span>;
}

export default function Header(
  { isAdminInterface, showTagline }: HeaderProps = {
    isAdminInterface: false,
    showTagline: false,
  }
) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h1" align="center">
          Tim &amp; Gareth
        </Typography>
        {showTagline && (
          <Typography align="center" sx={{ fontStyle: "italic" }}>
            <TagLine />
          </Typography>
        )}
        {isAdminInterface && (
          <Typography variant="h2" align="center" sx={{ marginTop: 0 }}>
            🛠️ Admin Interface
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: "15px",
          marginBottom: {
            xs: "5px",
            md: "20px",
          },
        }}
      >
        <Divider
          sx={{
            borderBottom: "1px solid black",
            maxWidth: {
              xs: "260px",
              md: "550px",
            },
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Divider
          sx={{
            borderBottom: "1px solid black",
            marginTop: "2px",
            maxWidth: {
              xs: "300px",
              md: "500px",
            },
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </Grid>
    </Grid>
  );
}
