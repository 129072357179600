import { PhotoAlbum } from "@timandgareth/domain";
import { useTranslation } from "react-i18next";

export default function AlbumTitle({ title, titleTranslations }: PhotoAlbum) {
  const { i18n } = useTranslation();

  let actualTitle = title;
  if (
    i18n.resolvedLanguage &&
    titleTranslations &&
    titleTranslations[i18n.resolvedLanguage]
  ) {
    actualTitle = titleTranslations[i18n.resolvedLanguage];
  }
  return <>{actualTitle}</>;
}
