import { AuthorizedRole } from "@timandgareth/domain";
import jwtDecode, { JwtPayload } from "jwt-decode";

export function isExpired(authToken?: string): boolean {
  if (!authToken) {
    // non-existant tokens are not expired! ^^
    return false;
  }
  const jwt = jwtDecode<JwtPayload>(authToken);
  return !jwt || !jwt.exp || jwt.exp * 1000 < Date.now();
}
