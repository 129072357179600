import React from "react";
import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { isValidEmail } from "../util/email";
import BaseAuthForm from "./BaseAuthForm";
import { Trans, useTranslation } from "react-i18next";

export enum RequestAccessResult {
  SUCCESS,
  FAILURE,
}

interface RequestAccessFormProps {
  initialEmail: string | undefined;
  onSubmit: (
    email: string,
    introduction: string
  ) => Promise<RequestAccessResult>;
  onBackClick: () => void;
}

export default function RequestAccessForm({
  initialEmail,
  onSubmit,
  onBackClick,
}: RequestAccessFormProps) {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string | undefined>(initialEmail);
  const [introduction, setIntroduction] = useState<string>();
  const [requestAccessSuccessful, setRequestAccessSuccessful] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event?: React.FormEvent<HTMLFormElement>) {
    if (!isValidEmail(email)) {
      setShowEmailError(true);
      return;
    }
    setLoading(true);
    try {
      event?.preventDefault();
      const requestAccessResult = await onSubmit(
        email?.trim() as string,
        introduction?.trim() as string
      );
      if (requestAccessResult === RequestAccessResult.SUCCESS) {
        // yay!
        setRequestAccessSuccessful(true);
      } else {
        // noooo -
        // TODO handle - show snackbar here!
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <BaseAuthForm>
      {!requestAccessSuccessful && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2" sx={{ marginTop: 0 }}>
              {t("request-access.title")}
            </Typography>
            <Typography>{t("request-access.ask")}</Typography>
            <Typography>
              <Trans
                i18nKey="request-access.reason"
                components={{ email: <code /> }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormLabel htmlFor="id-email">
              {t("request-access.form.email")}
            </FormLabel>
            <TextField
              id="id-email"
              type="email"
              required
              onChange={(e) => {
                setEmail(e.target.value);
                setShowEmailError(false);
              }}
              error={showEmailError}
              sx={{ width: 1 }}
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel htmlFor="id-intro">
              {t("request-access.form.introduction")}
            </FormLabel>
            <TextField
              id="id-intro"
              type="text"
              multiline
              rows={4}
              required
              onChange={(e) => {
                setIntroduction(e.target.value);
              }}
              sx={{ width: 1 }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button onClick={onBackClick} startIcon={<KeyboardArrowLeftIcon />}>
              {t("request-access.back")}
            </Button>
          </Grid>
          <Grid item xs={10}>
            <Button
              variant="contained"
              onClick={() => handleSubmit()}
              sx={{ width: 1 }}
              disabled={loading}
            >
              {!loading && <span>{t("request-access.submit")}</span>}
              {loading && <CircularProgress size={24} />}
            </Button>
          </Grid>
        </>
      )}
      {requestAccessSuccessful && (
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography>{t("request-access.thank-you")}</Typography>
          <Typography>{t("request-access.close-window")}</Typography>
          <Button
            onClick={() => window.location.reload()}
            startIcon={<KeyboardArrowLeftIcon />}
            sx={{
              marginTop: 2,
            }}
          >
            {t("request-access.back-to-start")}
          </Button>
        </Grid>
      )}
    </BaseAuthForm>
  );
}
