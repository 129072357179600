import React, { useEffect } from "react";
import CodedError, { ERROR_CODE_UNAUTHORIZED } from "./CodedError";
import { Navigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";

interface ErrorViewProps {
  error: Error | CodedError;
}

const isUnauthorizedError = (error: Error | CodedError) =>
  error instanceof CodedError && error.code === ERROR_CODE_UNAUTHORIZED;

export default function ErrorView({ error }: ErrorViewProps) {
  console.log("error view");
  console.log(error);

  useEffect(() => {
    if (isUnauthorizedError(error)) {
      window.location.assign("/");
      // TODO prefill email on login form
    }
  }, [error]);

  if (isUnauthorizedError(error)) {
    return (
      <Typography variant="h2">
        Unauthorized! Redirecting to login...
      </Typography>
    );
  }
  return (
    <>
      <Typography variant="h2">Unexpected error occured.</Typography>
      <Typography>{error.message}</Typography>
      <Button onClick={() => window.location.assign("/")}>Back to login</Button>
    </>
  );
}
