import React, { useState } from "react";
import {
  ListPhotoAlbumsMetaInfo,
  ListPhotoAlbumsResponseV3,
  PhotoAlbum,
} from "@timandgareth/domain";
import {
  Box,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Link as MuiLink,
} from "@mui/material";
import { orderBy, groupBy, keys, identity, chain } from "lodash";
import { Link, useLoaderData, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";
import Image from "mui-image";
import { useTranslation } from "react-i18next";
import AlbumTitle from "./AlbumTitle";

const DEFAULT_TAGS_COUNT = 8;
const DEFAULT_COVER_PHOTO_SRC = "https://placehold.co/600x400";

interface PhotoAlbumCardProps {
  album: PhotoAlbum;
  baseUrl: string;
}

function PhotoAlbumCard({ album, baseUrl }: PhotoAlbumCardProps) {
  const { jwt } = useAuth();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const imageHeight = isMobileView ? 100 : 200;
  const { coverPhotoFilename, url, isPrivate } = album;
  const coverImageSrc = coverPhotoFilename
    ? `${process.env.REACT_APP_SERVER_BASE_URL}/photos/v2/albums/${url}/thumbnails/${coverPhotoFilename}?jwt=${jwt}`
    : DEFAULT_COVER_PHOTO_SRC;
  return (
    <Grid item xs={6} sm={6} md={4}>
      <Link to={`${baseUrl}/${url}`} style={{ textDecoration: "none" }}>
        <Paper
          elevation={10}
          sx={{
            borderRadius: 3,
            border: "1px solid #aaaaaa",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            sx={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <Image
              src={coverImageSrc}
              duration={0}
              style={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                height: imageHeight,
                minHeight: imageHeight,
                maxHeight: imageHeight,
              }}
            />
          </CardMedia>
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              component="div"
              sx={{
                marginTop: isMobileView ? 0 : 1,
                marginBottom: 0,
                textAlign: "center",
              }}
            >
              <AlbumTitle {...album} />
              {isPrivate && <span style={{ marginLeft: 2 }}>🔒</span>}
            </Typography>
          </CardContent>
        </Paper>
      </Link>
    </Grid>
  );
}

interface PhotoAlbumListProps {
  title: string;
  baseUrl: string;
}

function NewTitle({
  title,
  albums,
  meta,
  handleTagSelectionChanged,
}: {
  title: string;
  albums: PhotoAlbum[];
  meta: ListPhotoAlbumsMetaInfo;
  handleTagSelectionChanged: (tags: string[]) => void;
}) {
  const { t } = useTranslation();
  const [showAllTags, setShowAllTags] = useState<boolean>(false);
  const allTags = [...meta.tags.selected, ...Object.keys(meta.tags.available)];
  const sortedTags = chain(allTags)
    .orderBy((tag) => tag, "asc") // alphabetically
    .orderBy((tag) => meta.tags.available[tag], "desc") // by number of albums
    .orderBy((tag) => meta.tags.selected.includes(tag), "desc") // selected ones first
    .value();
  const cutOffAfter = Math.max(
    Math.min(sortedTags.length, DEFAULT_TAGS_COUNT),
    meta.tags.selected.length
  );
  const shownTags =
    sortedTags.length > cutOffAfter && !showAllTags
      ? sortedTags.slice(0, cutOffAfter)
      : sortedTags;
  const tagsCutOff = shownTags.length < sortedTags.length;
  return (
    <Grid item md={12}>
      <Typography variant="h2">{title}</Typography>
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 1,
          display: "flex",
          flexWrap: "wrap",
          lineHeight: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginRight: 1,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {t("photos.filter-by-tags")}:
          </Typography>
        </Box>
        {shownTags.map((tag, index) => {
          let chip;
          const translatedTag = t(`photos.tags.${tag}`, {
            defaultValue: tag,
          });
          if (meta.tags.selected.includes(tag)) {
            chip = (
              <Chip
                label={translatedTag}
                onDelete={() =>
                  handleTagSelectionChanged(
                    meta.tags.selected.filter((t) => t !== tag)
                  )
                }
              />
            );
          } else {
            const tagAlbumCount = meta.tags.available[tag];
            const label = `${translatedTag} (${tagAlbumCount})`;
            chip = (
              <Chip
                label={label}
                onClick={() =>
                  handleTagSelectionChanged([...meta.tags.selected, tag])
                }
                variant="outlined"
              />
            );
          }
          return (
            <Box key={index} sx={{ padding: 0.2 }}>
              {chip}
            </Box>
          );
        })}
        {tagsCutOff && (
          <Box
            sx={{
              padding: 0.2,
              display: "flex",
              alignItems: "center",
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            <MuiLink component="button" onClick={() => setShowAllTags(true)}>
              {t("photos.tags.show-all")}
            </MuiLink>
          </Box>
        )}
        {meta.tags.selected.length > 0 && (
          <Box
            sx={{
              padding: 0.2,
              display: "flex",
              alignItems: "center",
              marginLeft: 1,
              marginRight: 1,
            }}
          >
            <MuiLink
              component="button"
              onClick={() => handleTagSelectionChanged([])}
            >
              {t("photos.tags.clear-all")}
            </MuiLink>
          </Box>
        )}
      </Box>
      <Box>
        <Typography>
          <i>
            {albums.length === meta.total && (
              <span>
                {t("photos.showing-albums", { count: albums.length })}
              </span>
            )}
            {albums.length !== meta.total && (
              <span>
                {t("photos.showing-albums-filtered", {
                  actual: albums.length,
                  count: meta.total,
                })}
              </span>
            )}
          </i>
        </Typography>
      </Box>
    </Grid>
  );
}

export default function PhotoAlbumList({
  title,
  baseUrl,
}: PhotoAlbumListProps) {
  const { meta, albums } = useLoaderData() as ListPhotoAlbumsResponseV3;
  const navigate = useNavigate();
  const groupedAlbums = groupBy(albums, "year");
  function handleTagSelectionChanged(tags: string[]) {
    if (tags.length === 0) {
      navigate(baseUrl);
    } else {
      const params = new URLSearchParams();
      tags.forEach((tag) => params.append("tags", tag));
      navigate(`${baseUrl}?${params.toString()}`);
    }
  }
  return (
    <Grid container>
      <NewTitle
        title={title}
        albums={albums}
        meta={meta}
        handleTagSelectionChanged={handleTagSelectionChanged}
      />
      {orderBy(keys(groupedAlbums), identity, "desc").map((year) => (
        <Grid item xs={12} key={year}>
          <Typography variant="h3">{year}</Typography>
          <Grid
            container
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ marginBottom: 7 }}
          >
            {orderBy(groupedAlbums[year], "url", "desc").map((album, index) => (
              <PhotoAlbumCard key={index} album={album} baseUrl={baseUrl} />
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
