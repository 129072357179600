import { Box, Grid, Typography } from "@mui/material";
import { ContactInfos } from "@timandgareth/domain";
import Image from "mui-image";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

export default function Home() {
  const { t } = useTranslation();
  const contactInfos = useLoaderData() as ContactInfos;
  return (
    <Grid container>
      <Grid item sm={12}>
        <Box
          sx={{
            margin: { sm: 2 },
            marginTop: { xs: 2 },
            marginBottom: { xs: 2 },
          }}
        >
          <Image
            src="/home-header.jpg"
            alt="Photo of Tim and Gareth"
            style={{ borderRadius: 10 }}
          />
        </Box>
        <Typography sx={{ textAlign: "center" }}>
          {t("home.welcome")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Typography variant="h2">{t("home.contact")}</Typography>
        <Typography>
          {contactInfos.name} <br />
          {contactInfos.address[0]} <br />
          {contactInfos.address[1]} <br />
          {contactInfos.country}
        </Typography>
        <Typography>
          <a href={"mailto:" + contactInfos.email["both"]}>
            {contactInfos.email["both"]}
          </a>{" "}
          ({t("home.email.both")})
          <br />
          <a href={"mailto:" + contactInfos.email["g"]}>
            {contactInfos.email["g"]}
          </a>{" "}
          (Gareth)
          <br />
          <a href={"mailto:" + contactInfos.email["t"]}>
            {contactInfos.email["t"]}
          </a>{" "}
          (Tim)
        </Typography>
        <Typography>
          {contactInfos.phone["g"]} (Gareth)
          <br />
          {contactInfos.phone["t"]} (Tim)
        </Typography>
      </Grid>
    </Grid>
  );
}
