import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import Image from "mui-image";
import { Trans, useTranslation } from "react-i18next";

interface TitleProps {
  title: string;
}

function Title({ title }: TitleProps) {
  return (
    <Grid item xs={12}>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
    </Grid>
  );
}

function Logo() {
  return (
    <Grid container sx={{ marginTop: 3 }}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: {
              xs: 250,
              md: 400,
            },
            marginTop: {
              xs: "2em",
            },
          }}
        >
          <Image src="/logo.svg" duration={0} />
        </Box>
      </Grid>
    </Grid>
  );
}

function TheStorySection() {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          maxWidth: "600px",
          float: "right",
          margin: { md: 2, xs: 1 },
          marginTop: { md: 0, xs: 0 },
        }}
      >
        <Image
          src="/story-header.jpg"
          alt="Photo of Tim and Gareth"
          style={{ borderRadius: "10px" }}
        />
      </Box>
      <Trans
        i18nKey="wedding.story.body"
        components={{ paragraph: <Typography /> }}
      />
    </Grid>
  );
}

export default function Content() {
  const { t } = useTranslation();
  return (
    <>
      <Box className="content">
        <Logo />
        <Grid item xs={12} container>
          <Title title={t("wedding.story")} />
          <TheStorySection />
        </Grid>
        <Grid item xs={12} container sx={{ justifyContent: "center" }}>
          <Button
            size="large"
            variant="contained"
            href="/photos?tags=wedding"
            startIcon={<PhotoLibraryIcon />}
            sx={{ marginTop: 5 }}
          >
            {t("wedding.to-photos")}
          </Button>
        </Grid>
      </Box>
    </>
  );
}
