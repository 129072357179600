import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import uniq from "lodash/uniq";
import flatMap from "lodash/flatMap";
import intersection from "lodash/intersection";
import { FormControlLabel, Switch } from "@mui/material";
import "./LocationMap.css";
import { Place } from "@timandgareth/domain";

export enum MarkerType {
  HOTEL = "HOTEL",
  POI = "POI",
  RED = "RED",
  CROSS = "CROSS",
  PLANE = "PLANE",
  EAT = "EAT",
  SIGHT = "SIGHT",
  DRINK = "DRINK",
}
export interface Location {
  key: string;
  title: string;
  subtitle?: string;
  description?: string;
  url?: string;
  googleMapsUrl?: string;
  position: {
    lat: number;
    long: number;
  };
  tags?: string[];
  markerLabel?: string;
  markerType?: MarkerType;
  showByDefault?: boolean;
}

export interface Line {
  title: string;
  coordinates: { lat: number; lng: number }[];
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
}

function MapComponent({
  center,
  zoom,
  places,
  lines,
  focusedLocationKey,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
  places: Place[];
  lines: Line[];
  focusedLocationKey?: string;
}) {
  const ref = React.createRef<HTMLDivElement>();

  const markerIcons = {
    [MarkerType.HOTEL]: {
      url: process.env.PUBLIC_URL + "/img/markers/hotel.png",
      scaledSize: new google.maps.Size(30, 30),
    },
    [MarkerType.POI]: {
      url: process.env.PUBLIC_URL + "/img/markers/info.png",
      scaledSize: new google.maps.Size(30, 30),
    },
    [MarkerType.RED]: {
      url: process.env.PUBLIC_URL + "/img/markers/red.png",
      scaledSize: new google.maps.Size(40, 40),
    },
    [MarkerType.CROSS]: {
      url: process.env.PUBLIC_URL + "/img/markers/cross.png",
      scaledSize: new google.maps.Size(60, 60),
    },
    [MarkerType.PLANE]: {
      url: process.env.PUBLIC_URL + "/img/markers/plane.png",
      scaledSize: new google.maps.Size(40, 40),
    },
    [MarkerType.EAT]: {
      url: process.env.PUBLIC_URL + "/img/markers/eat.png",
      scaledSize: new google.maps.Size(30, 30),
    },
    [MarkerType.SIGHT]: {
      url: process.env.PUBLIC_URL + "/img/markers/sight.png",
      scaledSize: new google.maps.Size(30, 30),
    },
    [MarkerType.DRINK]: {
      url: process.env.PUBLIC_URL + "/img/markers/drink.png",
      scaledSize: new google.maps.Size(30, 30),
    },
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "satellite", "hybrid", "terrain", "styled_map"],
      },
    });

    let openInfoWindow: google.maps.InfoWindow | undefined;

    lines.map((line: Line) => {
      const mapLine = new google.maps.Polyline({
        path: line.coordinates,
        strokeColor: line.strokeColor,
        strokeOpacity: line.strokeOpacity,
        strokeWeight: line.strokeWeight,
        map,
      });
      const infoWindow = new google.maps.InfoWindow({
        content: `<span>${line.title}</span>`,
      });
      mapLine.addListener(
        "click",
        function ({ latLng }: { latLng: google.maps.LatLng }) {
          closeOpenInfoWindow();
          infoWindow.setPosition(latLng);
          infoWindow.open({ map });
          openInfoWindow = infoWindow;
        }
      );
      return mapLine;
    });

    const markerInfoWindows = new Map<string, google.maps.InfoWindow>();
    const markers = new Map<string, google.maps.Marker>();
    for (let place of places) {
      markerInfoWindows.set(
        place.title, // key
        new google.maps.InfoWindow({
          content: `
          <div class="info-window">
            <h1>${place.title}</h1>
            ${place.subtitle ? `<h2>${place.subtitle}</h2>` : ""}
            ${place.description ? `<p>${place.description}</p>` : ""}
            ${
              place.url
                ? `<a href="${place.url}" target="_blank">Website</a>`
                : ""
            }
            ${place.url && place.googleMapsUrl ? "&nbsp;|&nbsp;" : ""}
            ${
              place.googleMapsUrl
                ? `<a href="${place.googleMapsUrl}" target="_blank">Directions on Google Maps</a>`
                : ""
            }
          </div>
            `,
          maxWidth: 300,
        })
      );
      markers.set(
        place.title,
        new google.maps.Marker({
          position: {
            lat: place.position.lat,
            lng: place.position.long,
          },
          map,
          title: place.title,
          icon: place.category ? markerIcons[place.category] : undefined,
          label: place.markerLabel,
        })
      );
    }
    // new MarkerClusterer({ markers: Array.from(markers.values()), map });

    function closeOpenInfoWindow() {
      if (openInfoWindow) {
        openInfoWindow.close();
      }
    }

    function showInfoWindow(locationKey: string) {
      closeOpenInfoWindow();
      const newWindow = markerInfoWindows.get(locationKey);
      openInfoWindow = newWindow;
      const marker = markers.get(locationKey);
      newWindow?.open({
        anchor: marker,
        map,
      });
    }

    map.addListener("click", closeOpenInfoWindow);

    for (let place of places) {
      const marker = markers.get(place.title);
      marker?.addListener("click", () => {
        showInfoWindow(place.title);
      });
    }

    if (focusedLocationKey) {
      showInfoWindow(focusedLocationKey);
      const marker = markers.get(focusedLocationKey);
      if (marker) {
        map.setCenter(marker.getPosition()!);
        map.setZoom(15);
      }
    }

    // TODO add legend
    // TODO add onClick thing for line(s)
  }, [places, focusedLocationKey]);

  return (
    <>
      <div
        ref={ref}
        id="map"
        style={{
          minHeight: "80vh",
          border: "1px solid gray",
          borderRadius: "5px",
        }}
      />
    </>
  );
}

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return <></>;
};

// TODO don't reset map when changing filters
export default function LocationMap({
  places,
  focusedLocationKey,
}: {
  places: Place[];
  focusedLocationKey?: string;
}) {
  const center = { lat: 51.45, lng: 7.216236 };
  const zoom = 12;
  return (
    <>
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
        render={render}
      >
        <MapComponent
          center={center}
          zoom={zoom}
          lines={[]}
          places={places}
          focusedLocationKey={focusedLocationKey}
        />
      </Wrapper>
    </>
  );
}
