import React, { useContext } from "react";
import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { FloatingMenu, LanguagePicker, LogoutButton } from "../components";
import Header from "../Header";
import AdminMenu from "./AdminMenu";
import { Feature } from "@timandgareth/domain";
import HomeIcon from "@mui/icons-material/Home";
import { AuthorizationContext } from "../AuthorizationContext";
import { useNavigate } from "react-router-dom";

interface AdminLayoutProps {
  children: JSX.Element;
}

function FloatingMenuItems() {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const { features } = useContext(AuthorizationContext);
  const navigate = useNavigate();
  return (
    <>
      <LanguagePicker size={isMobileView ? "small" : "large"} />
      {features.includes(Feature.ADMIN) && (
        <IconButton
          onClick={() => navigate("/")}
          size={isMobileView ? "small" : "large"}
        >
          <HomeIcon />
        </IconButton>
      )}

      <LogoutButton size={isMobileView ? "small" : "large"} />
    </>
  );
}

export default function AdminLayout({ children }: AdminLayoutProps) {
  return (
    <>
      <FloatingMenu>
        <FloatingMenuItems />
      </FloatingMenu>
      <Header isAdminInterface={true} showTagline={true} />
      <Grid container>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <AdminMenu />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </>
  );
}
