import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LocationMap } from "../components";
import { Place } from "@timandgareth/domain";

interface MapOverlayProps {
  places: Place[];
  focusedLocationKey?: string | undefined;
  onClose: () => void;
}

export default function MapOverlay({
  places,
  focusedLocationKey,
  onClose,
}: MapOverlayProps) {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullWidth={!isMobileView}
      fullScreen={isMobileView}
      maxWidth="xl"
      open={!!focusedLocationKey}
      onClose={onClose}
    >
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        <LocationMap places={places} focusedLocationKey={focusedLocationKey} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" endIcon={<CloseIcon />}>
          Close map
        </Button>
      </DialogActions>
    </Dialog>
  );
}
